<template>
  <div class="unOuter mainOuter">
    <div class="container_un2 mainContainer position-relative">
      <div class="theme_bg main-box mSubscription animated fadeInLeft ani_delay03 motion p-3 ani_delay09">
        <p class="ng-star-inserted">
          <small class="me-3">{{ $t('dashboard.subscriptionDate') }}</small>
        </p>
        <p class="ng-star-inserted">
          <span id="subscr_begin">{{ start_date }}</span>
          <span style="margin: 0 0.35em" v-if="start_date && end_date">~</span>
          <span id="subscr_end">{{ end_date }}</span>
          <small class="mx-2 text-muted">
            (<span id="t_subscr_remain">{{ $t('dashboard.remainDays') }}</span>:<span id="subscr_remain">{{ checkDay(end_date) }}</span>)
          </small>
        </p>
        <div class="ms-auto d-flex align-items-center">
          <small class="me-3">{{ $t('dashboard.subscriptionItem') }}</small>
          <span class="badge bg-secondary bg-warning text-dark"
          >{{ plan == 0 ? $t('common.nonSubscribe') : $t('dashboard.itemName') + plan }}</span>
          <span id="packagenm" style="color: #985329"></span>
        </div>
      </div>
      <div class="main-box mInvite animated fadeInUp ani_delay12">
        <p class="tit mb-3">
          <span id="main_txt07">{{ $t('dashboard.inviteLink') }}</span>
          <button
              id="in_click"
              class="btnC"
              v-clipboard:copy="link"
              v-clipboard:success="onCopy"
          >
            {{ $t('common.click') }}
          </button>
        </p>
        <div>
          <input type="text" id="invite" readonly="" class="ng-untouched ng-pristine ng-valid" v-model="link"/>
          <button id="btnCopy" class="bCopy card_bg5" v-clipboard:copy="link" v-clipboard:success="onCopy">
            {{ $t('common.copy') }}
          </button>
        </div>
      </div>
      <div class="main-box mNotice animated fadeInLeft ani_delay09 motion">
        <ul class="tit">
          <li id="m_notice">{{ $t('common.notice') }}</li>
          <li id="" class="btnC">
            <router-link to="/notice">+</router-link>
          </li>
        </ul>
        <ul class="notSub">
          <li class="name">{{ notice_title }}</li>
          <li
              class="content"
              style="
                line-height: 1.5em;
                height: 3em;
                overflow: hidden;
                margin-top: 10px;
              "
          >
            {{ notice_memo }}
          </li>
        </ul>
      </div>
      <div class="main-box mBonus flex animated fadeInRight ani_delay12" :data-length="balances.length">
        <template v-for="(balance, index) in balances">
          <div class="bar" v-if="index > 0"></div>

          <div class="myMining">
            <div class="myMining_tit">
              <p id="t_bonus52">{{balance.coin}} {{ $t('common.walletBalance') }}</p>
            </div>

            <p class="myMining_sub">
              <span id="disp_wpoint" class="ng-star-inserted">{{balance.balance}}</span>
              <span class="fillEth"></span>
            </p>
          </div>
        </template>
      </div>

      <div class="main-box mcs--block animated fadeInUp ani_delay15">
        <h1>
          MCS Vesting Plan
        </h1>

        <h2>
          <span>Total Amounts : 5,000,000,000 MCS</span>

          <button @click="openGitbook">MCS Gitbook</button>
        </h2>

        <div class="table--wrapper">
          <table class="table">
            <thead>
            <tr>
              <th>Allocation</th>
              <th>Percentage</th>
              <th>Tokens Allocated</th>
              <th>Vesting Schedule</th>
              <th></th>
            </tr>
            </thead>

            <tbody>
            <tr class="active">
              <td>Seed Sale</td>
              <td>5%</td>
              <td>250,000,000</td>
              <td>2 months lock, 24 months (linear vesting)</td>
<!--              <td><a>to participate</a></td>-->
            </tr>

            <tr>
              <td>Private Sale A</td>
              <td>13%</td>
              <td>650,000,000</td>
              <td>2% at TGE, 2 months lock, 16 months (linear vesting)</td>
              <td></td>
            </tr>

            <tr>
              <td>Private Sale B</td>
              <td>13%</td>
              <td>650,000,000</td>
              <td>12% at TGE, 1 month lock, 7 months (linear vesting)</td>
              <td></td>
            </tr>

            <tr>
              <td>Public Sale</td>
              <td>5%</td>
              <td>250,000,000</td>
              <td>6 months (linear vesting)</td>
              <td></td>
            </tr>

            <tr>
              <td>Liquidity Pool</td>
              <td>20%</td>
              <td>1,000,000,000</td>
              <td>19% at TGE, 12 months (linear vesting)</td>
              <td></td>
            </tr>

            <tr>
              <td>Team</td>
              <td>10%</td>
              <td>500,000,000</td>
              <td>9 months lock, 24 months (linear vesting)</td>
              <td></td>
            </tr>

            <tr>
              <td>Advisory</td>
              <td>4%</td>
              <td>200,000,000</td>
              <td>9 months lock, 15 months (linear vesting)</td>
              <td></td>
            </tr>

            <tr>
              <td>Ecosystem Incentives</td>
              <td>12%</td>
              <td>600,000,000</td>
              <td>36 months (linear vesting)</td>
              <td></td>
            </tr>

            <tr>
              <td>Marketing</td>
              <td>9%</td>
              <td>450,000,000</td>
              <td>1 month lock, 24 months (linear vesting)</td>
              <td></td>
            </tr>

            <tr>
              <td>DAO</td>
              <td>4%</td>
              <td>200,000,000</td>
              <td>6 months, 36 months (linear vesting)</td>
              <td></td>
            </tr>

            <tr>
              <td>Staking Pools</td>
              <td>5%</td>
              <td>250,000,000</td>
              <td>1 month, 46 months (linear vesting)</td>
              <td></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const CryptoJS = require("crypto-js");
require('moment-timezone')
import moment from "moment";
moment.tz.setDefault('Asia/Seoul');

export default {
  data() {
    return {
      plan: window.localStorage.getItem("plan"),
      current_date: moment(new Date()).format(),
      expire_check: false,
      start_date: "",
      end_date: "",
      day: "",

      notice_title: "",
      notice_memo: "",
      link: "1235465879",
      left: {},
      right: {},
      reffer: "",
      grade: "",
      total: "",
      r_bonus: "",
      g_bonus: "",
      c_bonus: "",
      t_bonus: "",

      balances: [],

      bot_access_token: "",
    };
  },
  mounted() {
    if (this.current_date < moment(this.$store.state.e_date).format()) {
      this.expire_check = true;
    }

    this.GetDashboardLine();
    this.GetDashboardBonus();
    this.GetDashboardWallet();
  },
  methods: {
    GetDashboardLine() {
      this.$axios.post("/member/dashboard/line", {}).then((res) => {
        if (res.status == 200) {
          if (res.data.code == "200") {
            const e_body = res.data.body;
            const bytes = CryptoJS.AES.decrypt(
                e_body,
                process.env.VUE_APP_SECRET_TOKEN
            );
            const d_res = bytes.toString(CryptoJS.enc.Utf8);
            const body = JSON.parse(d_res);
            this.left = body.left;
            this.right = body.right;
            this.total = body.total;
            this.reffer = body.reffer;
            this.grade = body.grade;
            this.link = body.link;
            this.start_date = body.s_date;
            this.end_date = body.e_date;
            this.notice_title = body.notice != undefined && body.notice.title != undefined
                ? body.notice.title : '';
            this.notice_memo = body.notice != undefined && body.notice.memo != undefined
                ? body.notice.memo : '';
          } else if (res.data.code == "9999") {
            this.$store.dispatch("SETLOGOUT").then(() => {
              window.location.href = "/sign/login";
            });
          }
        }
      });
    },
    GetDashboardBonus() {
      this.$axios.post("/member/dashboard/bonus", {}).then((res) => {
        if (res.status == 200) {
          if (res.data.code == "200") {
            const e_body = res.data.body;
            const bytes = CryptoJS.AES.decrypt(
                e_body,
                process.env.VUE_APP_SECRET_TOKEN
            );
            const d_res = bytes.toString(CryptoJS.enc.Utf8);
            const body = JSON.parse(d_res);
            this.t_bonus = body.total;
            this.g_bonus = body.grade;
            this.r_bonus = body.reffer;
            this.c_bonus = body.company;
          } else if (res.data.code == "9999") {
            this.$store.dispatch("SETLOGOUT").then(() => {
              window.location.href = "/sign/login";
            });
          }
        }
      });
    },
    GetDashboardWallet() {
      this.$axios.post("/member/dashboard/wallet", {}).then((res) => {
        if (res.status == 200) {
          if (res.data.code == "200") {
            const e_body = res.data.body;
            const bytes = CryptoJS.AES.decrypt(
                e_body,
                process.env.VUE_APP_SECRET_TOKEN
            );
            const d_res = bytes.toString(CryptoJS.enc.Utf8);
            const body = JSON.parse(d_res);

            this.balances = body.wallet.balances
          } else if (res.data.code == "9999") {
            this.$store.dispatch("SETLOGOUT").then(() => {
              window.location.href = "/sign/login";
            });
          }
        }
      });
    },
    onCopy(e) {
      this.$alert(this.$i18n.t('common.copied') + e.text);
    },
    checkDay(end_date) {
      if (end_date === '') {
        return ` 0${this.$i18n.t('common.days')}`;
      }

      const curDay = moment()
      const endDay = moment(end_date);
      const diffDays = endDay.diff(curDay, 'days') + 1;

      if (diffDays <= 0) {
        return this.$i18n.t('dashboard.expired');
      } else {
        return `${diffDays < 10 ? `0${diffDays}` : diffDays} ${this.$i18n.t('common.days')}`;
      }
    },

    openGitbook() {
      window.open('https://money-copy-signal.gitbook.io/ai-based-mcs-protocol/product/mcs-money-copy-signal')
    }
  },
};
</script>

<style>
.theme_bg {
  background-color: rgba(255, 255, 255, 0.1);
}

.mNotice .notSub .name {
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: calc(100% - 0px) !important;
  overflow: hidden;
  line-height: 1.6;
  font-weight: 300;
  float: left;
}
</style>

<style lang="scss" scoped>
@media all and (min-width: 1201px) {
  .mBonus {
    &[data-length="1"] {
      grid-template-columns: 1fr;
    }

    &[data-length="2"] {
      grid-template-columns: 1fr 1px 1fr;
    }

    &[data-length="3"] {
      grid-template-columns: 1fr 1px 1fr 1px 1fr;
    }
  }
}

.mainContainer {
  display: grid !important;
  grid-template-areas: "subscribe"
            "invite"
            "notice"
            "wallet"
            "mcs";
  grid-template-rows: repeat(5, max-content);
  column-gap: 2em;
}

.mBonus {
  grid-area: wallet;
  display: grid;
  justify-content: center;

  .bar {
    width: 100%;
    height: 1px;
  }
}

.mcs--block {
  grid-area: mcs;
  display: grid;
  width: 100%;
  background-color: #ffffff1a;

  h1 {
    color: rgb(255, 255, 255);
    font-weight: 600;
    font-size: 17px;
  }

  h2 {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
    color: rgb(255, 255, 255);
    font-size: 15px;

    button {
      padding: 4px 8px;
      border-radius: .375rem;
      background-color: rgb(255, 193, 7);
      font-weight: bold;
      font-size: 13px;
    }
  }

  .table--wrapper {
    overflow-x: scroll;

    table {
      border: 0 none;

      thead {
        tr {
          border-top: 1px solid #ffffff4d;
          border-bottom: 1px solid #ffffff4d;
          border-right: 0 none;
          border-left: 0 none;

          th {
            padding: .8rem 1rem;
            border: 0 none;
            background-color: transparent;
            text-align: center;
            color: rgb(255, 255, 255);
            word-break: keep-all;
            white-space: nowrap;
          }
        }
      }

      tbody {
        tr {
          border-top: 1px solid #ffffff4d;
          border-bottom: 1px solid #ffffff4d;
          border-right: 0 none;
          border-left: 0 none;

          td {
            padding: .8rem 1rem;
            background-color: transparent;
            text-align: center;
            color: #6c757d;
            word-break: keep-all;
            white-space: nowrap;
            transition: .3s color ease;

            > a {
              transition: .3s color ease;
            }
          }

          &.active,
          &:active,
          &:hover {
            td {
              color: rgb(255, 255, 255);

              > a {
                color: rgb(255, 193, 7);
              }
            }
          }
        }
      }
    }
  }
}

@media all and (min-width: 501px) {
  .mcs--block {
    h1 {
      font-size: 19px;
    }

    h2 {
      font-size: 17px;
    }
  }
}

@media all and (min-width: 701px) {
  .mainContainer {
    grid-template-areas: "subscribe subscribe"
            "invite invite"
            "notice wallet"
            "mcs mcs";
    grid-template-rows: repeat(4, max-content);
    grid-template-columns: repeat(2, 1fr);
  }
}

@media all and (min-width: 1201px) {
  .mainContainer {
    grid-template-areas: "subscribe invite"
            "notice wallet"
            "mcs mcs";
    grid-template-rows: repeat(4, max-content);
    grid-template-columns: repeat(2, 1fr);
  }

  .mBonus {
    grid-template-columns: 1fr 1px 1fr 1px 1fr;

    .bar {
      width: 1px;
      height: 100%;
    }
  }
}

@media all and (min-width: 1351px) {
  .mcs--block {
    h1 {
      font-size: 20px;
    }

    h2 {
      font-size: 18px;

      button {
        padding: 4px 8px;
        font-size: 15px;
      }
    }
  }
}
</style>
